.notfoundViewPort {
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-left: 4.5rem;
    width: calc(100% - 4.5rem);

    i {
        font-size: 3rem;
        color: var(--accent);
        background: var(--sub-background);
        padding: .7rem 1.8rem;
        border-radius: 100px;
        display: flex;
        gap: .5rem;
        align-items: center;
        justify-content: center;

        span {
            font-family: var(--font);
            font-weight: 900;
            font-size: 1.5rem;
            color: var(--text);
        }
    }

    .info {
        font-weight: 300;
        text-align: center;
        padding: 0rem 1rem;
    }

    button {
        background: var(--accent);
        padding: .8rem 1.5rem;
        border-radius: 7px;
        border: 0;
        font-weight: 600;
        font-family: var(--font);
        font-size: 1.2rem;
        color: white;
        cursor: pointer;

        &:hover {
            filter: brightness(.9);
        }
    }

}


@media screen and (max-width: 925px) {
    .notfoundViewPort {
        margin-left: unset;
        width: 100%;
    }
}