

.games-container {
    min-height: 100vh;
    margin-top: 6.5rem;
    margin-left: 4.5rem;
    width: calc(100% - 4.5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .games-hero {
        background-image: url("https://cdn.cloudflare.steamstatic.com/steam/apps/1248990/ss_05eaa0e2fe3cb508f375318803f4ad57c7549f4b.1920x1080.jpg?t=1631077584");
        background-size: cover;
        background-position: center;
        width: calc(100% - 2rem);
        padding: 4rem 0rem;
        margin: 0rem 1rem;
        position: relative;
        border-radius: 7px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            background: #0000004f;
            z-index: 0;
        }


        .title {
            font-size: 3rem;
            font-weight: 600;
            margin: 0;
            padding: 0;
            z-index: 1;
            text-align: center;
            line-height: 2.5rem;
            margin-bottom: .5rem;
        }

        .sub-title {
            margin: 0;
            padding: 0;
            font-weight: 300;
            font-size: .9rem;
            letter-spacing: 1px;
            z-index: 1;
            text-align: center;
        }
    }


    .game-list {
        width: calc(100% - 2rem);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: auto;
        gap: 1rem;
        padding: 1rem;
    }
}


@media screen and (max-width: 430px) {
    .games-container {
    
        .games-hero {
            padding: 0rem;
            margin: 0rem 1rem;
            aspect-ratio: 16 / 8;
  
    
    
    
            .title {
                font-size: 2.5rem;
            }
    
            .sub-title {
                font-size: .8rem;
            }
        }
    
    
        .game-list {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .content-card {
                width: calc(100% - 2rem);
            }
        }
    }
}


@media screen and (max-width: 925px) {
    .games-container {
        margin-left: unset;
        width: 100%;
    }
}