

.square .content-card {
    width: 10rem;
    aspect-ratio: 1 / 1;

    &::after {
        background: rgba(0, 0, 0, 0.829);
    }

    &:hover, &:active {
        outline: 4px solid var(--card-border);
        transform: scale(1.02);
        box-shadow: 0px 10px 20px 15px rgba(187, 187, 187, 0.123);
        // background-size: 110%;

        .card-info, &::after {
            opacity: 1;
        }

        .card-info {
            transform: translateX(0px);
        }
    }


    .card-info {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 652px) {
    .square.content {
        width: calc(100% - 2rem);

        .content-card {
            width: 6rem;
        }
       
        & > p {
            font-size: 1.5rem;
        }
    
    }

    .square .content-card {
        &:hover {
            outline: 4px solid var(--card-border);
            transform: scale(1.02);
            box-shadow: 0px 10px 20px 15px rgba(187, 187, 187, 0.123);
            // background-size: 110%;

            .card-info, &::after {
                opacity: 1;
            }

            .card-info {
                transform: translateX(0px);
            }
        }

        .card-info {
            font-size: 1rem;
        }
    }
}