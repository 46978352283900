.grid {
    width: calc(100% - 2rem);
    margin: 1rem;
    margin-top: 0rem;

    & > p {
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 1rem;
        margin-top: .5rem;
    }

    .grid-set1 {
        padding: 3rem 1rem;
        padding-top: 1.2rem;
        margin: -1rem;
        padding-bottom: 3rem;
        overflow-y: unset;
        max-width: 100%;
        overflow-x: auto;
        margin-bottom: -1rem;
        padding-left: 1rem !important;

        &::-webkit-scrollbar {
            display: none;
        }

        display: grid;
        grid-gap: 10px;
        padding: 10px;
        
        grid-template-columns: repeat(12, 7rem);
        grid-template-rows: 7rem 7rem;
        width: max-content;
        gap: 1rem;

        .react-horizontal-scrolling-menu--separator {
            display: none;
        }

        .react-horizontal-scrolling-menu--item {
            display: grid;
            width: 100%;

            .grid-card {
                position: relative;
                background-position: center;
                background-size: cover;
                transition: transform 300ms ease;
                cursor: pointer;
                overflow: hidden;

                &:hover {
                    .titleHolder {
                        opacity: 1;
                    }
                }

                .titleHolder {
                    font-weight: 600;
                    opacity: 0;
                    position: absolute;
                    inset: 0;
                    text-align: center;

                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;

                    padding: 0rem 1rem;
                    padding-bottom: .5rem;
                    box-shadow: inset 0px -20px 30px rgba(0, 0, 0, 0.589);
                    transition: opacity 300ms;
                    word-break: break-all;
                    text-shadow: 0px 3px 5px black;
                }

                &:hover {
                    transform: scale(1.05);
                }
            }
            
            
            &:nth-child(1) {
                grid-column: 1 / span 2;
                grid-row: 1 / 3;
            }

            // Separator

            &:nth-child(3) {
                grid-column: 3 / span 1;
                grid-row: 1 / span 1;
            }

            // Separator

            &:nth-child(5) {
                grid-column: 4 / span 1;
                grid-row: 1 / span 1;
            }

            // Separator

            &:nth-child(7) {
                grid-column: 3 / span 1;
                grid-row: 2 / span 1;
            }

            // Separator

            &:nth-child(9) {
                grid-column: 4 / span 1;
                grid-row: 2 / span 1;
            }

            // Separator

            &:nth-child(11) {
                grid-column: 5 / span 2;
                grid-row: 1 / span 2;
            }

            &:nth-child(13) {
                grid-column: 7 / span 1;
                grid-row: 1 / span 1;
            }

            &:nth-child(15) {
                grid-column: 8 / span 1;
                grid-row: 1 / span 1;
            }

            &:nth-child(17) {
                grid-column: 7 / span 1;
                grid-row: 2 / span 1;
            }

            &:nth-child(19) {
                grid-column: 8 / span 1;
                grid-row: 2 / span 1;
            }

            &:nth-child(21) {
                grid-column: 9 / span 2;
                grid-row: 1 / span 2;
            }

            &:nth-child(23) {
                grid-column: 11 / span 1;
                grid-row: 1 / span 1;
            }

            &:nth-child(25) {
                grid-column: 12 / span 1;
                grid-row: 1 / span 1;
            }

            &:nth-child(27) {
                grid-column: 11 / span 1;
                grid-row: 2 / span 1;
            }

            &:nth-child(29) {
                grid-column: 12 / span 1;
                grid-row: 2 / span 1;
            }

        }  
    }


    .grid-set2 {
        padding: 3rem 1rem;
        padding-top: 1.2rem;
        margin: -1rem;
        padding-bottom: 3rem;
        overflow-y: unset;
        max-width: 100%;
        overflow-x: auto;
        margin-bottom: -1rem;
        padding-left: 1rem !important;

        &::-webkit-scrollbar {
            display: none;
        }

        display: grid;
        grid-gap: 10px;
        padding: 10px;
        
        grid-template-columns: repeat(12, 7rem);
        grid-template-rows: 7rem 7rem 7rem;
        width: max-content;
        gap: 1rem;

        .react-horizontal-scrolling-menu--separator {
            display: none;
        }

        .react-horizontal-scrolling-menu--item {
            display: grid;
            width: 100%;

            .grid-card {
                position: relative;
                background-position: center;
                background-size: cover;
                transition: transform 300ms ease;
                cursor: pointer;

                &:hover {
                    transform: scale(1.05);
                }

                overflow: hidden;

                &:hover {
                    .titleHolder {
                        opacity: 1;
                    }
                }

                .titleHolder {
                    font-weight: 600;
                    opacity: 0;
                    position: absolute;
                    inset: 0;
                    text-align: center;

                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;

                    padding: 0rem 1rem;
                    padding-bottom: .5rem;
                    box-shadow: inset 0px -20px 30px rgba(0, 0, 0, 0.589);
                    transition: opacity 300ms;
                    word-break: break-all;
                    text-shadow: 0px 3px 5px black;
                }
            }
            
            
            &:nth-child(1) {
                grid-column: 1 / span 2;
                grid-row: 1 / 3;
            }

            // Separator

            &:nth-child(3) {
                grid-column: 1 / span 1;
                grid-row: 3 / span 1;
            }

            // Separator

            &:nth-child(5) {
                grid-column: 2 / span 1;
                grid-row: 3 / span 1;
            }

            // Separator

            &:nth-child(7) {
                grid-column: 3 / span 1;
                grid-row: 3 / span 1;
            }

            // Separator

            &:nth-child(9) {
                grid-column: 3 / span 1;
                grid-row: 2 / span 1;
            }

            // Separator

            &:nth-child(11) {
                grid-column: 3 / span 1;
                grid-row: 1 / span 1;
            }

            &:nth-child(13) {
                grid-column: 4 / span 1;
                grid-row: 1 / span 1;
            }

            &:nth-child(15) {
                grid-column: 5 / span 1;
                grid-row: 1 / span 1;
            }

            &:nth-child(17) {
                grid-column: 4 / span 2;
                grid-row: 2 / span 2;
            }

            &:nth-child(19) {
                grid-column: 6 / span 2;
                grid-row: 1 / span 2;
            }

            &:nth-child(21) {
                grid-column: 6 / span 1;
                grid-row: 3 / span 1;
            }

            &:nth-child(23) {
                grid-column: 7 / span 1;
                grid-row: 3 / span 1;
            }

            &:nth-child(25) {
                grid-column: 8 / span 1;
                grid-row: 3 / span 1;
            }

            &:nth-child(27) {
                grid-column: 8 / span 1;
                grid-row: 2 / span 1;
            }

            &:nth-child(29) {
                grid-column: 8 / span 1;
                grid-row: 1 / span 1;
            }

            &:nth-child(31) {
                grid-column: 9 / span 1;
                grid-row: 1 / span 1;
            }

            &:nth-child(33) {
                grid-column: 10 / span 1;
                grid-row: 1 / span 1;
            }

            &:nth-child(35) {
                grid-column: 9 / span 2;
                grid-row: 2 / span 2;
            }

            &:nth-child(37) {
                grid-column: 11 / span 2;
                grid-row: 1 / span 2;
            }
        }  
    }

    .circle {
        gap: .5rem;
    }

    .extraWide {
        grid-template-columns: repeat(12, 15rem);
    }
}