@keyframes revealPlus {
    0% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes revealMinus {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 925px) {
    .home {
        margin-left: unset !important;
        width: 100% !important;
    }
}

.home {
    margin-top: 6rem;
    margin-bottom: 6rem;
    margin-left: 4.5rem;
    width: calc(100% - 4.5rem);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .hero-wrapper {
        width: calc(100% - 4.4rem);
        padding: 1.2rem;
        margin: 1rem;
        height: 17rem;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(to bottom, #000000b0, #00000000);
        }

        .bg-handler {
            position: absolute;
            inset: 0;
            background-position: center;
            background-size: cover;
            transition: opacity 1s;
        }

        .bg-handler.hidden {
            opacity: 0;
        }

        

        .backgroundVideo {
            position: absolute;
            inset: 0;
            width: 100% ;
            padding: -1rem;
            object-fit: contain;
            object-position: center;
            z-index: 1;
            // animation: revealPlus 2000ms linear forwards;
        }

    
        .blackOverlay {
            content: '';
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, #000000b0, #00000000);
            z-index: 1;
            // animation: revealPlus 2000ms linear forwards;
        }

        // &:hover {
        //     .backgroundVideo {
        //         opacity: 1;
        //         transition: opacity 2000ms 1000ms;
        //     }

        //     .blackOverlay {
        //         opacity: 1;
        //         transition: opacity 2000ms 1000ms;
        //     }
        // }
    }

    .hero {
        width: 100%;
        height: 17rem;
        inset: 0;
        
        .hero-card {
            height: 20rem;
            
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: .5rem;

            .game-head {
                display: flex;
                align-items: flex-end;
                gap: 1rem;

                img{
                    height: 3.5rem;
                    border-radius: 10px;
    
                    &:first-child {
                        height: 4.5rem;
                    }
                }
            }

            .game-info {
                margin-left: .2rem;
                margin-top: 1rem;
                max-width: 28rem;
                color: white;
                user-select: none;

                .name {
                    font-size: 1.8rem;
                    font-weight: 700;
                    letter-spacing: 1px;
                }

                .info {
                    font-weight: 300;
                    letter-spacing: 1px;
                    line-height: 1.8rem;
                    font-size: .95rem;
                }

                .play-btn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    color: white;

                    

                    background: var(--light-accent);
                    width: 7.5rem;
                    font-size: 1.2rem;

                    font-weight: 600;
                    letter-spacing: 2px;

                    padding: .3rem 0rem;
                    padding-right: .4rem;
                    margin-top: 1rem;
                    user-select: none;
                    cursor: pointer;
                    border-radius: 7px;

                    transition: background 200ms;

                    i {
                        font-size: 2.5rem;
                    }

                    &:hover {
                        filter: brightness(.95);
                    }

                    &:active {
                        transform: scale(.95);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 652px) {
    .home {
        .hero-wrapper {
            width: calc(100% - 4.4rem);
            padding: 1.2rem;
            margin: 1rem;
            height: 16rem;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            
    
            &::after {
                content: '';
                position: absolute;
                inset: 0;
                background: linear-gradient(to bottom, #000000b0, #00000000);
            }
    
            .bg-handler {
                position: absolute;
                inset: 0;
                background-position: center;
                background-size: cover;
                transition: opacity 1s;
            }
    
            .bg-handler.hidden {
                opacity: 0;
            }
        }
    
        .hero {
            width: 100%;
            height: 16rem;
            inset: 0;
            
            .hero-card {
                height: 16rem;
                
                display: flex;
                flex-direction: column;
                align-items: center;
  
                gap: .5rem;
                
    
                .game-head {


                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                

                    img{
                        max-height: 2rem;
        
                        &:first-child {
                            max-height: 3.1rem;
                        }
                    }
                }

                .game-info {
                    margin-left: 0rem;
                    margin-top: 1rem;
                    max-width: 28rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .info {
                        text-align: center;
                        margin: 0rem 1rem;
                    }

                    .play-btn {
                        width: 7rem;
                        font-size: 1rem;
                        i {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }
    }
}