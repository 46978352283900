@keyframes revealPlay {
    0% {
        opacity: 0;
        transform: translate(0, 100%);
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translate(0, 0%);
    }
}

.play {
    z-index: 1111111;
    margin-left: 4.5rem;
    width: calc(100% - 4.5rem);
}

.play-cont {
    position: relative;
    inset: 0;
    padding-top: 6rem;
    padding-left: 0rem;
    padding-right: 0rem;
    width: 100%;
    min-height: 100vh;
    animation: revealPlay 2000ms cubic-bezier(0.46, 0.03, 0.52, 0.96);

    .game-cont {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-height: calc(100vh - 10rem);
        aspect-ratio: 16 / 9;
        display: flex;
        flex-direction: column;
        align-items: center;
        // border-radius: 7px;
        gap: .5rem;
    }

    .game-wrapper {
        position: relative;
        width: fit-content;
        max-width: calc(100% - 2.2rem);
        aspect-ratio: 16 / 9;
        height: 100%;
        max-height: calc(100vh - 10rem);
        background: var(--sub-background);
        // outline: 2px solid var(--sub-outline);
        margin-bottom: 3rem;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;

        

        .game{
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            border: none;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
        }
    }

    .expander {
        display: none;
    }

    .play-down-bar {
        position: absolute;
        bottom: -3.25rem;
        left: 0;
        right: 0;
        background: var(--sub-background);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        // outline: 1px solid var(--sub-accent);

        p.legionStamp {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: .2rem;
            font-family: var(--font);
            font-size: 1rem;
            font-weight: 200;
            letter-spacing: 1px;
            display: flex;
            align-items: center;
            gap: .2rem;
            color: white;
            text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.473);
            animation: revealPlay 500ms cubic-bezier(0.46, 0.03, 0.52, 0.96);

            span {
                display: flex;
                align-items: center;
                img {
                    width: 1.8rem;
                    filter: brightness(0) invert(1) drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.473));
                }
                font-weight: 600;
            }
         }

        .lCont {
            display: flex;
            align-items: center;
            padding-left: .8rem;
        }
        
        .play-logo {
            width: 2rem;
            height: 2rem;
            border-radius: 4px;
            background-size: cover;
            background-position: center;
        }

        .play-info {
            font-size: 1.2rem;
            font-weight: 600;
            letter-spacing: 1px;
            padding-left: 1rem;
            font-family: var(--font);
            color: var(--text);
        }

        .play-actions {
            display: flex;
            flex-direction: row;
            gap: .6rem;
            font-size: 1.2rem;
            color: var(--text);
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0.034), transparent);

            i {
                padding: 1rem;
                cursor: pointer;

                transition: background 300ms, color 50ms;

                &:hover {
                    background: var(--sub-background);
                }

                &:active {
                    background: var(--sub-accent);
                    color: black;
                }
            }

            .disabled {
                pointer-events: none;
                opacity: .2;
            }

            .bx.bx-exit-fullscreen {
                display: none;
            }
        }
    }
}

.play-cont.fullscreen {
    position: fixed;
    padding: 0 !important;
    width: 100vw;
    height: calc(100vh - 3.2rem);
    z-index: 111111111;

    .game-cont {
        all: unset;
    }

   

    .game {
        all: unset;
        position: fixed !important;
        inset: 0;
        width: 100vw;
        height: calc(100vh - 3.2rem);
        padding: 0;
    }

    .loading-overlay{
        position: fixed !important;
        inset: 0;
        width: 100vw;
        height: calc(100vh - 3.2rem);
        padding: 0;
        z-index: 1;
    }

    .play-down-bar {
        position: fixed;
        bottom: 0;

        p.legionStamp {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: .2rem;
            font-size: 1rem;
            font-weight: 200;
            letter-spacing: 1px;
            display: flex;
            align-items: center;
            gap: .2rem;
            color: white;
            text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.473);
            animation: revealPlay 500ms cubic-bezier(0.46, 0.03, 0.52, 0.96);

            span {
                display: flex;
                align-items: center;
                img {
                    width: 1.8rem;
                    filter: brightness(0) invert(1) drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.473));
                }
                font-weight: 600;
            }
         }
        


        .play-info {
            
        }
        .play-actions {
            color: var(--text);

            .bx.bx-fullscreen {
                display: none;
            }

            .bx.bx-exit-fullscreen {
                display: block;
            }
        }
    }

    .content {
        display: none;
    }

    .gameDesc {
        display: none !important;
    }
}

.play-cont.oldfullscreen {
    position: fixed;
    padding: 0 !important;
    width: 100vw;
    height: 100vh;
    z-index: 111111111;

    .game-cont {
        all: unset;
    }

   

    .game {
        all: unset;
        position: fixed !important;
        inset: 0;
        width: 100vw;
        height: 100vh;
        padding: 0;
    }

    .loading-overlay{
        position: fixed !important;
        inset: 0;
        width: 100vw;
        height: 100vh;
        padding: 0;
        z-index: 1;
    }

    .play-down-bar {
        all: unset;
     
        position: fixed;
        z-index: 111;
        bottom: 1rem;
        left: 50%;
        transform: translate(-50%, calc(100% + 1rem));
        transition: transform 300ms;

        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        padding-top: 0rem;
        padding-bottom: 0;
        padding-left: 1rem;
        gap: 1rem;
        border-radius: 10px;
        background: var(--background);
        

        &:hover {
            transform: translate(-50%, 0);
        }

        .expander {
            display: block;
            position: absolute;
            
            width: 100%;
            height: 1.5rem;
            left: 0;
            top: -1.5rem;
            display: grid;
            place-content: center;
    
            .pill {
                border-radius: 10rem;
                height: .25rem;
                width: 5rem;
                background: white;
            }
        }

        .play-info {
            font-family: var(--font);
            font-size: .5rem;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: 5px;
            line-height: .5rem;
            color: var(--text);
            height: fit-content;
            transform: translateY(1px);
        }
        .play-actions {
            background: unset;
            gap: 0;
            color: var(--text);

            .bx.bx-fullscreen {
                display: none;
            }

            .bx.bx-exit-fullscreen {
                display: block;
            }
        }
    }

    .content {
        display: none;
    }
}

.gameDesc {
    margin: 1rem;
    padding: 1rem;
    width: calc(100% - 4rem);
    background: var(--sub-background);
    border-radius: 7px;

    h1 {
        color: var(--text);
        margin: 0;
        padding: 0;
        font-size: 1.7rem;
        font-weight: 700;
        letter-spacing: 1px;
    }

    h2, pre {
        color: var(--text);
        margin-top: .2rem;
        padding: 0;
        font-size: .9rem;
        font-weight: 400;
        opacity: .8;
        letter-spacing: 1.3px;
        line-height: 2rem;
    }

    pre {
        line-height: 2rem;
        font-family: var(--font);
    }

    h3 {
        color: var(--text);
        margin: 0;
        margin-top: 2rem;
    }


    .categories {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        gap: .8rem;
        
        & > div {
            cursor: pointer;
            border-radius: 7px;
            padding: .5rem 1rem;
            background: var(--sub-outline);
        }
    }

}

@media screen and (max-width: 925px) {
    .play {
        margin-left: unset;
        width: 100%;
    }
}