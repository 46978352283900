.collections {
    width: calc(100% - 2rem);
    margin: 1rem;
    margin-top: 0rem;

    display: flex;
    flex-direction: column;
    gap: .5rem;
    

    & > p {
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 1rem;
        margin-top: .5rem;
    }

    .card-list {
        display: flex;
        gap: .7rem;
        padding: 3rem 1rem;
        padding-top: 1.2rem;
        margin: -1rem;
        padding-bottom: 3rem;
        overflow-y: unset;
        max-width: 100%;
        overflow-x: auto;
        margin-bottom: -3rem;

        // overflow: unset;
  

        &::-webkit-scrollbar {
            display: none;
        }


        .collection-card {
            position: relative;
            width: 14rem;
            aspect-ratio: 1 / 1 !important;
            background: var(--sub-background);
            border-radius: 7px;
            outline: 4px solid transparent;
            text-decoration: none;
            color: white;
        
            background-position: center;
            background-size: cover;
            // overflow: hidden;
        
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            padding: 1rem;
            user-select: none;
            border: 2px solid var(--sub-outline);
        
            .cover {
                width: 100%;
                height: calc(100% - 2rem);
                background-size: cover;
                background-position: center;
                 border-radius: 7px;
            }

            .title {
                color: var(--text);
                font-weight: 600;
            }

            button {
                background: var(--sub-outline);
                border: 0;
                color: var(--text);
                padding: .5rem 1rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .5rem;
                border-radius: 7px;
                cursor: pointer;
                font-family: var(--font);
                letter-spacing: 1px;
                font-size: .9rem;
                transition: background 300ms ease;

                &:hover {
                    background: var(--sub-accent);
                }
            }



        


        }


    }

}




// @media screen and (max-width: 652px) {
//     .content {
//         width: calc(100% - 2rem);

//         .content-card {
//             width: 10rem;
//         }
       
//         & > p {
//             font-size: 1.2rem;
//         }
    
//     }

//     .content-card {
//         &:hover {
//             outline: 4px solid var(--card-border);
//             transform: scale(1.02);
//             box-shadow: 0px 10px 20px 15px rgba(187, 187, 187, 0.123);
//             // background-size: 110%;

//             .card-info, &::after {
//                 opacity: 1;
//             }

//             .card-info {
//                 transform: translateX(0px);
//             }
//         }

//         .card-info {
//             font-size: 1.2rem;
//         }
//     }
// }