@keyframes revealLoading {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes hideLoading {
    100% {
        opacity: 0;
    }
}

.splash-game-screen {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 1111;
    background: var(--sub-background);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

    animation: revealLoading 900ms cubic-bezier(0.46, 0.03, 0.52, 0.96);

    .loadingIframe {
        width: 100%;
        height: 100%;
        border: 0;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
    }

    .loadingCont {
        position: relative;
        width: 100%;
        height: 100%;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;

        .loadingImage {
            position: absolute;
            bottom: 0;
            width: 3rem;
        }

        .bg {
            position: absolute;
            inset: 0;
            background-image: url("https://d.newsweek.com/en/full/1117559/eso1830a.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -1;

            &::after {
                content: '';
                position: absolute;
                inset: 0;
                backdrop-filter: blur(8px);
            }
        }

        .logo {
            aspect-ratio: 1 / 1;
            width: 15vmax;
            max-width: 8rem;
            border-radius: 100px;
            background: var(--sub-background);
            background-image: url('https://media.discordapp.net/attachments/727068553873850398/1010798066468663337/Plundur.io.png');
            background-size: cover;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.473);
        }

        button {
            border: 0;
            background: #92E95F;
            border-radius: 13px;
            font-size: 1.8vmax;
            font-weight: 800;
            letter-spacing: 2px;
            padding: 1.5vmax 1.8vmax;
            line-height: 1.8vmax;
            transition: transform 300ms ease;
            cursor: pointer;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.473);

            &:hover {
                transform: scale(1.1);
            }

            &:active {
                transform: scale(1.05);
            }
        }

         p {
            height: .2rem;
            font-size: 1.5vmax;
            font-weight: 200;
            letter-spacing: 1px;
            display: flex;
            align-items: center;
            gap: .2rem;
            color: white;
            text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.473);
            

            span {
                display: flex;
                align-items: center;
                img {
                    width: 2.4vmax;
                    filter: brightness(0) invert(1) drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.473));
                }
                font-weight: 600;
            }
         }
    }
}

.splash-game-screen.hide {
    animation: hideLoading 900ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
}