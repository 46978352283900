.loading-panel {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 1111;
    background: var(--sub-background);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    display: grid;
    place-content: center;

    animation: revealLoading 900ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

.loading-panel.hide {
    animation: hideLoading 900ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
}